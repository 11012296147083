import {
  branchType,
  Banner,
  ProductBanner,
  ProductMobile,
  SaleChannel,
  Video,
  Privilege,
  StampCard,
  Coupon,
  PromotionsNews,
  FriendGetFriend,
  Notifications,
  CampaignForms,
  MemberTier,
  Automation,
  SendMessage,
  Redeem,
  Customer,
  Segment,
  Product,
  Transaction,
  ExtraPoint,
  AllRightRequest,
  Setting,
  ActivityLog,
  Staff,
  Permission,
  Role,
  Branch,
  Booking,
  Promotions,
  ProductAnalysis,
  TransactionCode,
} from "../path";

function configRoutes() {
  return [
    {
      path: "/",
      name: "Login",
      component: () => import("@/views/pages/Login"),
    },
    {
      path: "/azureauth/login",
      name: "Azure Auth callback",
      component: () => import("@/views/pages/AzureAuthLogin"),
    },
    {
      path: "/home",
      name: "Home",
      redirect: "/customer-analysis",
      component: () => import("@/containers/TheContainer"),
      meta: { requiresAuth: true, lv: 1 },
      children: [
        Banner,
        ProductBanner,
        ProductMobile,
        SaleChannel,
        Video,
        branchType,
        Privilege,
        StampCard,
        Coupon,
        PromotionsNews,
        FriendGetFriend,
        Notifications,
        CampaignForms,
        Automation,
        SendMessage,
        MemberTier,
        Redeem,
        Customer,
        Segment,
        Product,
        Transaction,
        ExtraPoint,
        AllRightRequest,
        Setting,
        ActivityLog,
        Staff,
        Permission,
        Role,
        Branch,
        Booking,
        Promotions,
        ProductAnalysis,
        TransactionCode,
        // {
        //   path: "/customer-analysis",
        //   name: "Customer Analysis",
        //   component: () => import("@/views/pages/dashboard"),
        //   meta: { requiresAuth: true },
        // },
        {
          path: "/bundle-product",
          name: "Bundle Product",
          component: () => import("@/views/pages/bundleProduct"),
          meta: { requiresAuth: true },
        },
        {
          path: "/customer-report-file",
          name: "Customer Report List",
          component: () => import("@/views/pages/customerReportFile"),
          meta: { requiresAuth: true },
        },
        {
          path: "/transaction-redeem-point",
          name: "Transaction Redeem Point",
          component: () => import("@/views/pages/transactionRedeemPoint"),
          meta: { requiresAuth: true },
        },
        {
          path: "/coupon-chart",
          name: "Coupon Analysis",
          component: () => import("@/views/pages/couponChart"),
          meta: { requiresAuth: true },
        },
        {
          path: "/redeem-chart",
          name: "Redeem Analysis",
          component: () => import("@/views/pages/redeemChart"),
          meta: { requiresAuth: true },
        },
        {
          path: "/prospect-chart",
          name: "Prospect",
          component: () => import("@/views/pages/Prospect"),
          meta: { requiresAuth: true },
        },
        {
          path: "/promotion-chart",
          name: "Promotion Analysis",
          component: () => import("@/views/pages/promotionChart"),
          meta: { requiresAuth: true },
        },
        {
          path: "/service-chart",
          name: "Service Analysis",
          component: () => import("@/views/pages/serviceChart"),
          meta: { requiresAuth: true, activeUrl: "/service-chart" },
        },
        {
          path: "/customer-analysis",
          name: "Customer Analysis",

          component: () => import("@/views/pages/dashboard"),
          meta: { requiresAuth: true, activeUrl: "/customer-analysis" },
        },
        {
          path: "/channel-analysis",
          name: "Channel Analysis",
          component: () => import("@/views/pages/channelAnalysis"),
          meta: { requiresAuth: true },
        },
        {
          path: "/welcome",
          name: "Welcome",
          redirect: "/report/customer",
          component: () => import("@/views/pages/Welcome"),
          meta: { requiresAuth: true },
        },
        {
          path: "/category",
          name: "Category",
          component: () => import("@/views/pages/category/Index"),
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/category",
            parent: "Product Mobile ",
          },
        },
        {
          path: "/automation/imagemap",
          name: "Automation Image Map",
          component: () =>
            import("@/views/pages/automation/detail/imageMap/ImageMap.vue"),
          meta: {
            requiresAuth: true,

            isChildren: true,
            activeUrl: "/automation",
          },
        },
        {
          path: "/customer/importdata",
          name: "Customer Import Data",
          component: () => import("@/views/pages/customer/import/ImportFile"),
          meta: { requiresAuth: true },
        },
        {
          path: "/customer/importpoint",
          name: "Customer Import Point",
          component: () => import("@/views/pages/customer/import/ImportPoint"),
          meta: { requiresAuth: true },
        },
        {
          path: "/customer/configdata",
          name: "Customer Config Data",
          component: () =>
            import("@/views/pages/customer/import/configField.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/customer/checkfield",
          name: "Customer Check Data",
          component: () =>
            import("@/views/pages/customer/import/checkField.vue"),
          meta: { requiresAuth: true },
        },
      ],
    },

    {
      path: "/500",
      name: "Page500",
      component: () => import("@/views/pages/Page500"),
    },
    {
      path: "*",
      name: "Page404",
      component: () => import("@/views/pages/Page404"),
    },
    {
      path: "/403",
      name: "Page403",
      component: () => import("@/views/pages/Page403"),
    },
  ];
}
export default configRoutes;
