import Vue from "vue";
const utility = null;
Vue.prototype.ChartTicked = (number) => {
  if (number > 10000) {
    return 5000;
  } else if (number >= 5000) return 1000;
  else if (number >= 1000) return 200;
  else if (number >= 500) return 100;
  else if (number >= 300) return 50;
  else if (number >= 100) return 10;
  else if (number < 100 && number > 20) return 5;
  else if (number <= 20 && number > 10) return 2;
  else if (number <= 10) return 1;
};
export default utility;
