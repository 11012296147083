import Vue from "vue";
import axios from "axios";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export const state = () => ({
  state: {
    modalAlertLogin: false,
    list: {},
    eventList: {},
    conditionList: {},
    memberLevel: {},
    genderList: {},
    campaignList: {},
    connectChannel: null,
    redeemCodeList: {},
    communicationList: {},
    messageType: {},
    respDelete: {},
    respCreate: {},
    respDetail: {},
    respUpdateAutomation: {},
    userRegister: {},
    imageMap: {},
    respCreateImageMap: {},
    respUpdateImageMap: {},
    segmentationList: {},
    respCreateSegmentation: {},
    segmentationById: {},
    respUpdateSegmentation: {},
    cancelSendMessage: {},
    resendMessage: {},
    userSegmentation: {},
    segmenById: {},
    respExportAutomation: {},
    respExportSegmentation: {},
    privilegeCodeInAutomation: {},
    stampCardInAutomation: {},
    stateTestSendSMSOrEmail: null,
    stateCheckFileExcel: null,
  },
});

export const mutations = {
  setModalAlertLogin: async function (state, val) {
    state.modalAlertLogin = val;
  },
  setList: async function (state, val) {
    state.list = val;
  },
  setEventList: async function (state, val) {
    state.eventList = val;
  },
  setConditionList: async function (state, val) {
    state.conditionList = val;
  },
  setMemberLevel: async function (state, val) {
    state.memberLevel = val;
  },
  setGenderList: async function (state, val) {
    state.genderList = val;
  },
  setcampaignList: async function (state, val) {
    state.campaignList = val;
  },
  setConnectChannel: async function (state, val) {
    state.connectChannel = val;
  },
  setRedeemCodeList: async function (state, val) {
    state.redeemCodeList = val;
  },
  setCommunicationList: async function (state, val) {
    state.communicationList = val;
  },
  setMessageType: async function (state, val) {
    state.messageType = val;
  },
  setDeleteAutomation: async function (state, val) {
    state.respDelete = val;
  },
  setRespCreate: async function (state, val) {
    state.respCreate = val;
  },
  setRespDetail: async function (state, val) {
    state.respDetail = val;
  },
  setUpdateAutomation: async function (state, val) {
    state.respUpdateAutomation = val;
  },
  setUserRegister: async function (state, val) {
    state.userRegister = val;
  },
  setImageMap: async function (state, val) {
    state.imageMap = val;
  },
  setCreateImagMap: async function (state, val) {
    state.respCreateImageMap = val;
  },
  setUpdateImagMap: async function (state, val) {
    state.respUpdateImageMap = val;
  },
  setSegmentationList: async function (state, val) {
    state.segmentationList = val;
  },
  setRespCreateSegmentation: async function (state, val) {
    state.respCreateSegmentation = val;
  },
  setSegmentationById: async function (state, val) {
    state.segmentationById = val;
  },
  setRespUpdateSegmentation: async function (state, val) {
    state.respUpdateSegmentation = val;
  },
  setCancelSendMessage: async function (state, val) {
    state.cancelSendMessage = val;
  },
  setResendMessage: async function (state, val) {
    state.resendMessage = val;
  },
  setUserSegmentation: async function (state, val) {
    state.userSegmentation = val;
  },
  setSegmentById: async function (state, val) {
    state.segmenById = val;
  },
  setRespExportAutomation: async function (state, val) {
    state.respExportAutomation = val;
  },
  setRespExportSegmentation: async function (state, val) {
    state.respExportSegmentation = val;
  },
  setPrivilegeCodeInAutomation: async function (state, val) {
    state.privilegeCodeInAutomation = val;
  },
  setStampCardInAutomation: async function (state, val) {
    state.stampCardInAutomation = val;
  },
  setTestSendSMSOrEmail: async function (state, val) {
    state.stateTestSendSMSOrEmail = val;
  },
  setCheckFileExcel: async function (state, val) {
    state.stateCheckFileExcel = val;
  },
};

export const actions = {
  getList: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/MarketingAutomation/getlist`, payload)
      .then(
        async (data) => {
          commit("setList", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getEvent: async ({ commit }) => {
    await axios
      .get(`${process.env.VUE_APP_API}/MarketingAutomation/getevent`)
      .then(
        async (data) => {
          commit("setEventList", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getCondition: async ({ commit }) => {
    await axios
      .get(`${process.env.VUE_APP_API}/MarketingAutomation/getcondition`)
      .then(
        async (data) => {
          commit("setConditionList", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getMemberLevel: async ({ commit }) => {
    await axios
      .get(`${process.env.VUE_APP_API}/MarketingAutomation/getmemberlevel`)
      .then(
        async (data) => {
          commit("setMemberLevel", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getGender: async ({ commit }) => {
    await axios
      .get(`${process.env.VUE_APP_API}/MarketingAutomation/getgender`)
      .then(
        async (data) => {
          commit("setGenderList", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getCampaign: async ({ commit }) => {
    await axios
      .get(`${process.env.VUE_APP_API}/MarketingAutomation/getcampaign`)
      .then(
        async (data) => {
          commit("setcampaignList", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getSaleChannel: async ({ commit }) => {
    await axios
      .get(`${process.env.VUE_APP_API}/MarketingAutomation/getsalechannel`)
      .then(
        async (data) => {
          console.log(data);
          commit("setConnectChannel", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getRedeemCode: async ({ commit }, payload) => {
    await axios
      .get(
        `${process.env.VUE_APP_API}/MarketingAutomation/getredeemcode?AutomationId=${payload}`
      )
      .then(
        async (data) => {
          commit("setRedeemCodeList", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getCommunication: async ({ commit }) => {
    await axios
      .get(`${process.env.VUE_APP_API}/MarketingAutomation/getcommunication`)
      .then(
        async (data) => {
          commit("setCommunicationList", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getMessageType: async ({ commit }) => {
    await axios
      .get(`${process.env.VUE_APP_API}/MarketingAutomation/getmessagetype`)
      .then(
        async (data) => {
          commit("setMessageType", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  deleteAutomation: async ({ commit }, payload) => {
    await axios
      .delete(`${process.env.VUE_APP_API}/MarketingAutomation/${payload}`)
      .then(
        async (data) => {
          commit("setDeleteAutomation", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  createAutomation: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/MarketingAutomation`, payload)
      .then(
        async (data) => {
          commit("setRespCreate", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getAutomationById: async ({ commit }, payload) => {
    await axios
      .get(`${process.env.VUE_APP_API}/MarketingAutomation/${payload}`)
      .then(
        async (data) => {
          commit("setRespDetail", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  updateAutomation: async ({ commit }, payload) => {
    await axios
      .put(
        `${process.env.VUE_APP_API}/MarketingAutomation/${payload.id}`,
        payload.form
      )
      .then(
        async (data) => {
          commit("setUpdateAutomation", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getUserRegister: async ({ commit }, payload) => {
    await axios
      .post(
        `${process.env.VUE_APP_API}/MarketingAutomation/getoverviewbyid/${payload.id}`,
        payload.filter
      )
      .then(
        async (data) => {
          commit("setUserRegister", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getImageMap: async ({ commit }, payload) => {
    await axios
      .get(`${process.env.VUE_APP_API}/MarketingAutomation/getimagemap`)
      .then(
        async (data) => {
          commit("setImageMap", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  createImageMap: async ({ commit }, payload) => {
    await axios
      .post(
        `${process.env.VUE_APP_API}/MarketingAutomation/createimagemap`,
        payload
      )
      .then(
        async (data) => {
          commit("setCreateImagMap", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  updateImageMap: async ({ commit }, payload) => {
    await axios
      .put(
        `${process.env.VUE_APP_API}/MarketingAutomation/updateimagemap`,
        payload
      )
      .then(
        async (data) => {
          commit("setUpdateImagMap", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getSegmentationList: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/Message/getlist`, payload)
      .then(
        async (data) => {
          commit("setSegmentationList", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  createSegmentation: async ({ commit }, payload) => {
    await axios.post(`${process.env.VUE_APP_API}/Message`, payload).then(
      async (data) => {
        commit("setRespCreateSegmentation", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  getSegmentationById: async ({ commit }, payload) => {
    await axios.get(`${process.env.VUE_APP_API}/Message/${payload}`).then(
      async (data) => {
        commit("setSegmentationById", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  updateSegmentation: async ({ commit }, payload) => {
    await axios
      .put(`${process.env.VUE_APP_API}/Message/${payload.id}`, payload.form)
      .then(
        async (data) => {
          commit("setRespUpdateSegmentation", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  cancelMessge: async ({ commit }, payload) => {
    await axios
      .get(`${process.env.VUE_APP_API}/Message/cancelmessage/${payload}`)
      .then(
        async (data) => {
          commit("setCancelSendMessage", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  resendSendMessage: async ({ commit }, payload) => {
    await axios
      .get(`${process.env.VUE_APP_API}/Message/resend/${payload}`)
      .then(
        async (data) => {
          commit("setResendMessage", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getUserSendMessage: async ({ commit }, payload) => {
    await axios
      .post(
        `${process.env.VUE_APP_API}/Message/getoverviewbyid/${payload.id}`,
        payload.filter
      )
      .then(
        async (data) => {
          commit("setUserSegmentation", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  exportAutomationUser: async ({ commit }, payload) => {
    await axios
      .get(
        `${process.env.VUE_APP_API}/MarketingAutomation/exportautomationuser/${payload}`,
        {
          // headers: {
          //     'Content-Type': 'multipart/form-data',
          //     'Authorization': `Bearer ${VueCookies.get('back_office_admin_token').token}`,
          //     'API-KEY': process.env.VUE_APP_API_KEY
          // },
          responseType: "blob",
        }
      )
      .then(
        (data) => {
          commit("setRespExportAutomation", data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  exportSegmentationUser: async ({ commit }, payload) => {
    await axios
      .post(
        `${process.env.VUE_APP_API}/Message/exportsegmentationuser`,
        payload
      )
      .then(
        (data) => {
          commit("setRespExportSegmentation", data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getPrivilegeCodeInAutomation: async ({ commit }, payload) => {
    await axios
      .get(`${process.env.VUE_APP_API}/MarketingAutomation/getprivilege`)
      .then(
        async (data) => {
          commit("setPrivilegeCodeInAutomation", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getStampCardInAutomation: async ({ commit }, payload) => {
    await axios
      .get(`${process.env.VUE_APP_API}/MarketingAutomation/getstampcard`)
      .then(
        async (data) => {
          commit("setStampCardInAutomation", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  testSendSMSOrEmail: async ({ commit }, payload) => {
    await axios
      .post(
        `${process.env.VUE_APP_API}/MarketingAutomation/TestCommunication`,
        payload
      )
      .then(
        async (data) => {
          commit("setTestSendSMSOrEmail", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  checkFileExcel: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/Message/iscolumnsegmentation`, payload)
      .then(
        async (data) => {
          commit("setCheckFileExcel", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
};

export default {
  state,
  actions,
  mutations,
};
