import Vue from "vue";
import axios from "axios";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export const state = () => ({
  state: {
    couponList: [],
    modalAlertLogin: false,
    branchList: [],
    couponProductList: [],
    responseCreateCoupon: [],
    couponDetail: [],
    statusUpdateCoupon: {},
    customerUserCouponList: {},
    respExportUsedCoupon: {},
  },
});

export const mutations = {
  setCouponList: async function (state, val) {
    state.couponList = val;
  },
  setModalAlertLogin: async function (state, val) {
    state.modalAlertLogin = val;
  },
  setBranchList: async function (state, val) {
    state.branchList = val;
  },
  setCouponProductList: async function (state, val) {
    state.couponProductList = val;
  },
  setResponseCreateCoupon: async function (state, val) {
    state.responseCreateCoupon = val;
  },
  setCouponDetail: async function (state, val) {
    state.couponDetail = val;
  },
  setUpdateCouponDetail: async function (state, val) {
    state.statusUpdateCoupon = val;
  },
  setCustomerUserCouponList: async function (state, val) {
    state.customerUserCouponList = val;
  },
  setRespExportCustomerUsedCoupon: async function (state, val) {
    state.respExportUsedCoupon = val;
  },
};

export const actions = {
  getCouponList: async ({ commit }, payload) => {
    await axios.post(`${process.env.VUE_APP_API}/Coupon/List`, payload).then(
      async (data) => {
        if (data.data.result == 1) {
          commit("setCouponList", data.data);
        }
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  getBranch: async ({ commit }, payload) => {
    await axios
      .get(
        `${process.env.VUE_APP_API}/Coupon/GetCouponBranch?BranchGroupId=${payload}`
      )
      .then(
        async (data) => {
          if (data.data.result == 1) {
            commit("setBranchList", data.data);
          }
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getCouponProductList: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/coupon/product`, payload.form)
      .then(async (data) => {
        if (data.data.result == 1) {
          commit("setCouponProductList", data.data);
        }
      });
  },
  createCoupon: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/coupon`, payload)
      .then(async (data) => {
        commit("setResponseCreateCoupon", data.data);
      });
  },
  getCouponDetail: async ({ commit }, payload) => {
    await axios
      .get(`${process.env.VUE_APP_API}/coupon/${payload}`)
      .then(async (data) => {
        if (data.data.result == 1) {
          commit("setCouponDetail", data.data);
        }
      });
  },
  updateCouponById: async ({ commit }, payload) => {
    await axios
      .put(`${process.env.VUE_APP_API}/coupon/${payload.id}`, payload.form)
      .then(async (data) => {
        commit("setUpdateCouponDetail", data.data);
      });
  },
  getCustomerUsedCouponList: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/Coupon/user_coupon_list`, payload)
      .then(async (data) => {
        commit("setCustomerUserCouponList", data.data);
      });
  },
  exportCustomerUsedCoupon: async ({ commit }, payload) => {
    await axios
      .post(`${process.env.VUE_APP_API}/Coupon/export_customer_list`, payload, {
        responseType: "blob",
      })
      .then(
        (data) => {
          commit("setRespExportCustomerUsedCoupon", data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
        }
      );
  },
};

export default {
  state,
  actions,
  mutations,
};
